import { useEffect, useState } from "react";
import { Dimensions, Pressable, StyleSheet } from "react-native";

import { Modal, Text, Token, View } from "@ctv/shared-core/src";

import IcSystemCrossClose from "@traveloka/icon-kit-web/react/IcSystemCrossClose";

type LogType = "log" | "warn" | "error";

type LogEntry = {
  type: LogType;
  message: string;
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
};

export default function LoggingModal({ isVisible, onClose }: Props) {
  const [logs, setLogs] = useState<LogEntry[]>([]);

  const { height, width } = Dimensions.get("window");

  useEffect(() => {
    const originalConsoleLog = console.log;
    const originalConsoleWarn = console.warn;
    const originalConsoleError = console.error;

    const stringifySafe = (arg: unknown): string => {
      if (typeof arg === "string") return arg;
      try {
        return JSON.stringify(arg, null, 2);
      } catch (error) {
        return String(arg);
      }
    };

    const overrideConsole = (
      type: LogType,
      originalFn: (...args: unknown[]) => void
    ) => {
      return (...args: unknown[]) => {
        const logMessage = args.map(stringifySafe).join(" ");
        setLogs((prevLogs) => [...prevLogs, { type, message: logMessage }]);
        originalFn(...args);
      };
    };

    console.log = overrideConsole("log", originalConsoleLog);
    console.warn = overrideConsole("warn", originalConsoleWarn);
    console.error = overrideConsole("error", originalConsoleError);

    return () => {
      console.log = originalConsoleLog;
      console.warn = originalConsoleWarn;
      console.error = originalConsoleError;
    };
  }, []);

  return (
    <View style={styles.wrapper}>
      <Modal
        visible={isVisible}
        render={() => {
          return (
            <View
              flex
              style={[
                styles.container,
                { width: width, minHeight: height, maxHeight: height },
              ]}
            >
              <View style={styles.marginL} row justify="between">
                <Text variant="title-1">Developer Tools</Text>
                <Pressable onPress={onClose}>
                  <IcSystemCrossClose
                    width={24}
                    height={24}
                    color={Token.color.darkPrimary}
                  />
                </Pressable>
              </View>
              {logs.map((log, index) => {
                const logStyles = styles[log.type];
                return (
                  <View key={index} style={[styles.paddingM, logStyles]}>
                    <Text variant="ui-small">{log.type.toUpperCase()}:</Text>
                    <Text>
                      <pre
                        style={{
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {log.message}
                      </pre>
                    </Text>
                  </View>
                );
              })}
            </View>
          );
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    // @ts-expect-error
    position: "fixed",
    bottom: 20,
    left: 20,
    zIndex: 999,
  },
  container: {
    overflow: "scroll",
    backgroundColor: Token.color.lightPrimary,
  },
  marginL: {
    margin: Token.spacing.l,
  },
  paddingM: {
    padding: Token.spacing.m,
  },
  log: {},
  warn: {
    backgroundColor: Token.opacity.seeThrough(Token.color.yellowSecondary),
  },
  error: {
    backgroundColor: Token.opacity.obscure(Token.color.redSecondary),
  },
});
