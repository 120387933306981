"use client";
import htmr from "htmr";
import {
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInputChangeEventData,
  TextInputKeyPressEventData,
} from "react-native";

import { useContentResource } from "@ctv/core/resource";
import {
  Button,
  Card,
  formatMessage,
  Input,
  Text,
  Token,
  View,
} from "@ctv/shared-core/src";
import useSafeCountdown from "@ctv/shared-core/src/hooks/useSafeCountdown";

import { useAuth } from "../../CognitoAuthContext";

export type OtpRefType = {
  getOtp(): string;
  startCountdown(sec: number): void;
};

const START_OTP_TIMER = 30;

type Props = {
  otpRef?: MutableRefObject<OtpRefType>;
  otpMaxLength: number;
  isSubmitting: boolean;
  isError: boolean;
  errorMessage?: string;
  onComplete?(): void;
  onResendOtp(): Promise<boolean | undefined>;
};

export default function OtpField(props: Props) {
  const {
    otpRef,
    otpMaxLength,
    isSubmitting,
    isError,
    errorMessage,
    onComplete,
    onResendOtp,
  } = props;
  const [otp, setOtp] = useState<string[]>(new Array(otpMaxLength).fill(""));
  const { countdown, startCountdown } = useSafeCountdown(START_OTP_TIMER);

  const { CorporateEmailOtp: cr } = useContentResource();
  const { email } = useAuth().user!;

  useImperativeHandle(otpRef, () => ({
    getOtp() {
      return otp.join("");
    },
    startCountdown,
  }));

  const handleChange = (
    index: number,
    e: NativeSyntheticEvent<TextInputChangeEventData>
  ) => {
    // nativeEvent.data is the new added value (excluding the existing value)
    // e.nativeEvent.text is to cover for the unit testing, since the jest didn't fill the data event
    // @ts-expect-error -- different text input typing between rn and react
    const value = e.nativeEvent.data ?? e.nativeEvent.text;
    if (isNaN(+value)) return;

    setOtp((prev) => {
      const newOtp = [...prev];

      if (value.length === 1) {
        newOtp[index] = value;
        focusTo(index + 1);
      } else if (value.length > 1) {
        // Handle copy and paste
        const indexLeft = Math.min(value.length, otpMaxLength) - index;
        Array.from({ length: indexLeft }).forEach((_, newValueIndex) => {
          newOtp[index + newValueIndex] = value[newValueIndex];
        });
        focusTo(index + indexLeft);
      }

      return newOtp;
    });
  };

  function focusTo(index: number) {
    const input = document.getElementById(`otp-${index}`);
    if (input) input.focus();
  }

  const handleKeyDown = (
    index: number,
    e: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => {
    // @ts-expect-error -- different text input typing between rn and react
    if (e.key === "Backspace") {
      setOtp((prev) => {
        const newOtp = [...prev];
        const currentBox = prev[index];
        if (currentBox) {
          newOtp[index] = "";
          return newOtp;
        }

        if (index > 0) {
          newOtp[index - 1] = "";
          const prevInput = document.getElementById(`otp-${index - 1}`);
          if (prevInput) prevInput.focus();
        } else {
          newOtp[index] = "";
        }

        return newOtp;
      });
    }
  };

  useEffect(() => {
    if (otp.every(Boolean)) {
      onComplete?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run on otp change
  }, [otp]);

  async function handleResendOtp() {
    const needToWait = await onResendOtp();

    if (needToWait) {
      startCountdown(START_OTP_TIMER);
      return;
    }

    startCountdown(0);
  }

  return (
    <Card style={styles.container} spacing="m">
      <Text variant="title-1">{cr.title}</Text>
      <Text variant="ui-small">
        {htmr(formatMessage(cr.description, { email }))}
      </Text>
      <View spacing="xs" align="center">
        <View row spacing="xs">
          {otp.map((value, index) => (
            <Input
              key={index}
              testID={`otp-${index}`}
              id={`otp-${index}`}
              autoFocus={index === 0}
              value={value}
              onChange={(e) => handleChange(index, e)}
              onKeyPress={(e) => handleKeyDown(index, e)}
              placeholder="_"
              placeholderTextColor={Token.color.bluePrimary}
              style={styles.input}
              inputStyle={styles.inputText}
              error={isError}
            />
          ))}
        </View>
        {errorMessage && (
          <Text testID="error_message" variant="ui-small" ink="alert">
            {errorMessage}
          </Text>
        )}
      </View>
      <View spacing="xs">
        <Button
          testID="resend_otp"
          text={
            countdown.sec > 0
              ? formatMessage(cr.btnWaitResendCode, {
                  sec: Math.max(countdown.sec, 0),
                })
              : cr.btnResendCode
          }
          variant="secondary"
          onPress={handleResendOtp}
          disabled={countdown.sec > 0 || isSubmitting}
        />
        <Button
          testID="submit_otp"
          text={cr.btnVerify}
          variant="primary"
          onPress={onComplete}
          disabled={isSubmitting || otp.filter(Boolean).length !== otpMaxLength}
          loading={isSubmitting}
        />
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: Token.spacing.l,
    margin: "auto",
    width: 432,
  },
  input: {
    width: 32,
    height: 40,
  },
  inputText: {
    textAlign: "center",
    ...Token.typography.title1,
  },
  placeholderStyle: {
    color: Token.color.bluePrimary,
  },
});
