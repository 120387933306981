import { get } from "@ctv/shared/utils/storage";

import { isProduction } from "../config/env";

export const isMocked = !isProduction && Boolean(get("mockMfaGate"));

export const isJest = false;

export const mockedOtpData = {
  approvalGate: false,
  authenticated: true,
  email: "fake-email",
  flagged: false,
  internalIpGate: false,
  mfaGate: true,
  specialLoginGate: false,
};
