import { differenceInSeconds } from "date-fns";
import { useEffect, useRef, useState } from "react";

export default function useSafeCountdown(seconds: number, startOnLoad = true) {
  const [countdown, setCountdown] = useState({
    sec: seconds,
    currentTime: Date.now(),
  });
  const currentTimeRef = useRef(seconds);

  function startTimer() {
    const interval = setInterval(() => {
      if (currentTimeRef.current <= 0) {
        clearInterval(interval);
        return;
      }

      setCountdown((prev) => {
        const currentTime = Date.now();
        const secDiff = differenceInSeconds(currentTime, prev.currentTime);
        const currentSec = prev.sec - secDiff;
        currentTimeRef.current = currentSec;
        return { sec: currentSec, currentTime };
      });
    }, 1050);

    return interval;
  }

  useEffect(() => {
    if (!startOnLoad) return;

    const interval = startTimer();

    return () => clearInterval(interval);
  }, []);

  function startCountdown(seconds: number) {
    const previousSeconds = currentTimeRef.current;
    currentTimeRef.current = seconds;

    setCountdown({ sec: seconds, currentTime: Date.now() });

    if (previousSeconds <= 0) {
      startTimer();
    }
  }

  return { countdown, startCountdown };
}
