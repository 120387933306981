"use client";
import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { View } from "@ctv/shared-core/src";
import BlankHeader from "@ctv/shared/components/Layout/BlankHeader";

import { useContentResource } from "../resource";
import {
  MfaGatewayCheckResponse,
  useMfaGatewayCheck,
  useMfaGatewayRequestOtp,
  useMfaGatewayVerifyOtp,
} from "./apis";
import AuthLoading from "./AuthLoading";
import MfaGatewayApiError from "./components/mfa-otp/MfaGatewayApiError";
import OtpField, { OtpRefType } from "./components/mfa-otp/OtpField";
import { isMocked, mockedOtpData } from "./constants";

export const MAX_OTP_LENGTH = 6;

export default function CheckMfaGateway({
  children,
}: PropsWithChildren<unknown>) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mfaData, setMfaData] = useState<{
    isError?: boolean;
    data?: MfaGatewayCheckResponse;
  }>();
  const [byPassMfa, setByPassMfa] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const refOtp = useRef<OtpRefType>(null!);

  const checkMfaGateway = useMfaGatewayCheck();
  const requestMfaOtp = useMfaGatewayRequestOtp();
  const verifyMfaOtp = useMfaGatewayVerifyOtp();
  const { CorporateEmailOtp: cr } = useContentResource();

  function fetchMfaGateway() {
    if (isMocked) {
      setMfaData({
        data: mockedOtpData,
      });
      return;
    }

    checkMfaGateway()
      .then((res) => {
        if (res) {
          setMfaData({ data: res });
          return;
        }
        setMfaData({ isError: true });
      })
      .catch(() => {
        console.error("Failed to fetch MFA gateway");
      });
  }

  useEffect(() => {
    fetchMfaGateway();
  }, []);

  async function requestOtpEmail() {
    if (isMocked) {
      return;
    }

    if (
      mfaData === undefined ||
      mfaData === null ||
      mfaData.data === undefined
    ) {
      return;
    }

    if (!mfaData.data.mfaGate && mfaData.isError) {
      return;
    }

    try {
      const res = await requestMfaOtp({ email: mfaData.data.email });

      if (!res) {
        setErrorMessage(cr.resendOtpErrorText);
        console.error(
          "Failed to send OTP email, please retry again. API response is null"
        );
        return false;
      }

      if (
        res.gatewayMessage ===
        "Email is already verified for the current session."
      ) {
        setByPassMfa(true);
      }

      if (res.gatewayError) {
        setErrorMessage(cr.resendOtpErrorText);
        console.error(
          "Failed to send OTP email, please retry again. API response has error"
        );
        return false;
      }
      return true;
    } catch (err) {
      setErrorMessage(cr.resendOtpErrorText);
      console.error("Failed to send OTP email, please retry again." + err);
      return false;
    }
  }

  function verifyOtp() {
    if (isMocked) {
      return;
    }

    if (
      mfaData === undefined ||
      mfaData === null ||
      mfaData.data === undefined
    ) {
      return;
    }

    if (!mfaData.data.mfaGate && mfaData.isError) {
      return;
    }

    const verificationToken = refOtp.current?.getOtp();
    if (!verificationToken) {
      return;
    }

    setIsSubmitting(true);
    verifyMfaOtp({ verificationToken })
      .then((res) => {
        if (res && !res.gatewayError) {
          fetchMfaGateway();
          return;
        }
        setErrorMessage(cr.submitOtpErrorText);
      })
      .catch((err) => {
        setErrorMessage(cr.submitOtpErrorText);
        console.error("Failed to verify OTP email" + err);
      })
      .finally(() => setIsSubmitting(false));
  }

  useEffect(() => {
    if (!mfaData?.data?.mfaGate) return;

    const needToWait = requestOtpEmail();

    if (!needToWait) {
      refOtp.current?.startCountdown(0);
    }
  }, [mfaData?.data?.mfaGate]);

  if (mfaData === undefined) {
    return <AuthLoading />;
  }

  if (mfaData.isError || mfaData.data === undefined) {
    return <MfaGatewayApiError />;
  }

  if (!mfaData.data.mfaGate || byPassMfa) {
    return <>{children}</>;
  }

  return (
    <View style={styles.background} align="center" spacing="xxl" flex>
      <BlankHeader />
      <OtpField
        otpRef={refOtp}
        otpMaxLength={MAX_OTP_LENGTH}
        isError={errorMessage === cr.submitOtpErrorText}
        errorMessage={errorMessage}
        isSubmitting={isSubmitting}
        onComplete={verifyOtp}
        onResendOtp={requestOtpEmail}
      />
    </View>
  );
}

const styles = {
  background: {
    backgroundColor: "rgba(11, 11, 11,0.6)", // 0B0B0B Bloom Overlay Scrims converted to rgba
  },
};
