"use client";
import { StyleSheet } from "react-native";

import { useContentResource } from "@ctv/core/resource";
import { Button, formatMessage, Image, Text, View } from "@ctv/shared-core/src";
import useSafeCountdown from "@ctv/shared-core/src/hooks/useSafeCountdown";
import BlankHeader from "@ctv/shared/components/Layout/BlankHeader";

const START_TO_RELOAD = 5;

export default function MfaGatewayApiError() {
  const { countdown } = useSafeCountdown(START_TO_RELOAD);

  const { CorporateEmailOtp: cr } = useContentResource();

  return (
    <>
      <BlankHeader />
      <View style={styles.container} flex align="center" spacing="m">
        <Image
          src="/images/warning.png"
          alt="mfa gateway error"
          width={100}
          height={100}
        />
        <Text testID="mfa_gateway_error" variant="title-1">
          {cr.mfaGatewayErrorTitle}
        </Text>
        <Text>
          {formatMessage(cr.mfaGatewayErrorDescription, {
            sec: countdown.sec,
          })}
        </Text>
        {countdown.sec <= 0 && (
          <Button
            text={cr.mfaGatewayErrorButtonText}
            variant="text-primary"
            onPress={() => window.location.reload()}
          />
        )}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: "20%",
  },
});
