import { DispatchWithoutAction, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import { Config } from "@ctv/core/config/env/types";
import {
  Button,
  Card,
  Fade,
  InputDropdown,
  InputField,
  Portal,
  Text,
  Token,
  View,
} from "@ctv/shared-core/src";
import {
  getPartialCookieName,
  removeCookie,
  setCookie,
} from "@ctv/shared-core/src/utils/cookie";
import { useDispatchSnackbar } from "@ctv/shared/contexts/SnackbarContext";

import IcSystemCrossClose12 from "@traveloka/icon-kit-web/react/IcSystemCrossClose12";

type Domain = keyof Pick<
  Config["apiHost"],
  "booking" | "management" | "search" | "refund"
>;

const items: Array<{ label: Domain; value: Domain }> = [
  { label: "search", value: "search" },
  { label: "booking", value: "booking" },
  { label: "refund", value: "refund" },
  { label: "management", value: "management" },
];

type Props = {
  isParentVisible: boolean;
  forceRender: DispatchWithoutAction;
};

export default function OverwriteApiDomainContent(props: Props) {
  const { isParentVisible, forceRender } = props;

  const [isActiveOverwriteVisible, setIsActiveOverwriteVisible] =
    useState(false);
  const [selectedDomain, setSelectedDomain] =
    useState<keyof Config["apiHost"]>();
  const [overwritePort, setOverwritePort] = useState("8080");

  const showSnackbar = useDispatchSnackbar();

  function handleOverwrite() {
    setCookie(`apiHost-${selectedDomain}`, overwritePort, 8 * 60 * 60); // 8 hours
    showSnackbar({
      type: "snackbar",
      message: `Overwrite "${selectedDomain}" with "localhost:${overwritePort}"`,
      variant: "positive",
    });
    setSelectedDomain(undefined);
    forceRender();
  }

  const overwriteApiDomainCookies = getPartialCookieName("apiHost-");

  useEffect(() => {
    if (!isParentVisible) {
      setIsActiveOverwriteVisible(false);
    }
  }, [isParentVisible]);

  function removeApiOverwrite(key: string) {
    removeCookie(key);
    forceRender();
  }

  return (
    <>
      <View>
        <Button
          fit
          size="small"
          variant="text-primary"
          text="View Active Overwrite"
          disabled={!overwriteApiDomainCookies?.length}
          onPress={() => setIsActiveOverwriteVisible((prev) => !prev)}
        />
        <InputDropdown
          containerStyle={Token.setZIndex(1)}
          label="Domain"
          items={items.filter(
            (item) =>
              !overwriteApiDomainCookies?.find((cookie) =>
                cookie.key.includes(item.value)
              )
          )}
          value={selectedDomain ?? ""}
          onPressItem={(item) => setSelectedDomain(item.value)}
          placeholder="Select domain"
          position="up"
          dropdownOffset={52}
        />
        <InputField
          label="Input port"
          onChangeText={setOverwritePort}
          value={overwritePort}
          placeholder="Input your localhost port"
        />
        <Button
          onPress={handleOverwrite}
          text="Overwrite domain"
          disabled={!selectedDomain || !overwritePort}
        />
      </View>
      <Portal>
        <Fade style={styles.fade} visible={isActiveOverwriteVisible}>
          <Card style={styles.card} spacing="s">
            <Text variant="title-3" ink="positive">
              Active Overwrite
            </Text>
            <View>
              {overwriteApiDomainCookies?.map((cookie) => (
                <View key={cookie.key} row>
                  <View flex={1}>
                    <Text variant="ui-small">
                      {cookie.key.replace("apiHost-", "")}:
                    </Text>
                  </View>
                  <View flex={2}>
                    <Text variant="ui-tiny">{cookie.value}</Text>
                  </View>
                  <TouchableOpacity
                    onPress={() => removeApiOverwrite(cookie.key)}
                  >
                    <IcSystemCrossClose12 width={12} height={12} />
                  </TouchableOpacity>
                </View>
              ))}
            </View>
          </Card>
        </Fade>
      </Portal>
    </>
  );
}

const styles = StyleSheet.create({
  fade: {
    // @ts-expect-error -- position fixed is not supported in react-native
    position: "fixed",
    left: 420,
    bottom: 20,
    zIndex: 1,
  },
  card: {
    // @ts-expect-error -- overflow auto is not supported in react-native
    overflow: "auto",
    width: 250,
    height: 300,
    padding: Token.spacing.s,
    backgroundColor: Token.color.lightPrimary,
  },
});
