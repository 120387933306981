"use client";
import { useMemo } from "react";
import { useAuth } from "../auth";
import { useFeatureControl } from "./FeatureControlContext";

type FeatureControlPropertiesWithSegmentation =
  | {
      key: "email";
      global: "true" | "false" | string | undefined;
      eligible: string[] | undefined;
    }
  | {
      key: "corporateCode";
      global: undefined;
      eligible: string[] | undefined;
    };

export default function useFeatureControlSegmentation(fcKey: string) {
  const { user } = useAuth();
  const { enabled, properties } =
    useFeatureControl<FeatureControlPropertiesWithSegmentation>(fcKey);

  return useMemo(() => {
    const { global, eligible, key = "email" } = properties;

    if (!enabled || !properties || !eligible || !user) {
      return { enabled, properties };
    }

    if (global === "false" || typeof global === "undefined") {
      if (!eligible.includes(user[key])) {
        return { enabled: false, properties };
      }
      return { enabled, properties };
    }

    return { enabled, properties };
  }, [user, enabled, properties]);
}
