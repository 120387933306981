import { Fragment, useState } from "react";
import { StyleSheet } from "react-native";

import {
  Button,
  Card,
  Divider,
  Fade,
  Text,
  Token,
  View,
} from "@ctv/shared-core/src";
import Snackbar from "@ctv/shared/contexts/Snackbar";

import IcFoodDonut from "@traveloka/icon-kit-web/react/IcFoodDonut";

import LoggingModal from "./LoggingModal";
import OverwriteApiDomain from "./OverwriteApiDomain/OverwriteApiDomain";

export type ToolType = "logging-modal" | "overwrite-api-domain";

export default function DeveloperToolsContent() {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [contentType, setContentType] = useState<ToolType>();

  function handleChangeContentType(type: ToolType) {
    setContentType((prev) => (prev !== type ? type : undefined));
  }

  const contents = [
    {
      name: "logging-modal",
      Component: (
        <Text
          style={styles.item}
          onPress={() => handleChangeContentType("logging-modal")}
        >
          Logging Modal
        </Text>
      ),
    },
    {
      name: "overwrite-api-domain",
      Component: (
        <OverwriteApiDomain
          isVisible={contentType === "overwrite-api-domain"}
          setContentType={() => handleChangeContentType("overwrite-api-domain")}
        />
      ),
    },
  ];

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <Button
          variant="text-white"
          startIcon={
            <View>
              <IcFoodDonut
                width={32}
                height={32}
                color={Token.color.godwitBlue}
                accentColor={Token.color.highAltitude}
              />
            </View>
          }
          onPress={() => setIsContentVisible((prev) => !prev)}
        />
        <Fade visible={isContentVisible}>
          <Card style={styles.content} spacing="s" overflow="hidden">
            <Text style={styles.title} variant="title-1">
              Developer Tool
            </Text>
            <View>
              {contents.map((content) => (
                <Fragment key={content.name}>
                  <Divider />
                  <View>{content.Component}</View>
                </Fragment>
              ))}
            </View>
          </Card>
        </Fade>
      </View>
      <LoggingModal
        isVisible={contentType === "logging-modal"}
        onClose={() => setContentType(undefined)}
      />
      <Snackbar />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // @ts-expect-error
    position: "fixed",
    bottom: 20,
    left: 20,
    zIndex: 999,
  },
  wrapper: {
    position: "relative",
  },
  content: {
    position: "absolute",
    left: 40,
    bottom: 0,
    width: 350,
    backgroundColor: Token.color.lightPrimary,
  },
  title: {
    padding: Token.spacing.m,
  },
  item: {
    padding: Token.spacing.s,
  },
});
