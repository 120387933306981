import { PropsWithChildren, useMemo } from "react";

import { useAuth, useFeatureControl } from "@ctv/core";
import { FC_ENABLE_DEVTOOLS } from "@ctv/core/feature-control/constants";
import { AccountStatusProvider } from "@ctv/core/providers/account-status/AccountStatusContext";
import { CreditLimitProvider } from "@ctv/core/providers/credit-limit/CreditLimitContext";
import ProfileProvider from "@ctv/core/providers/profile/ProfileContext";

import DeveloperToolsContent from "../_components/DeveloperTools/DeveloperToolsContent";
import { FlightStaticDataProvider, StaticData } from "./flight-static-data";

type Props = {
  staticData: StaticData;
};

export default function Providers({
  children,
  staticData,
}: PropsWithChildren<Props>) {
  const { user } = useAuth();
  const { enabled, properties } = useFeatureControl(FC_ENABLE_DEVTOOLS);

  const isEnabledDevTools = useMemo(() => {
    if (!enabled || !user) return false;
    const props = properties as { eligible?: string[] };
    return !!props?.eligible?.includes(user.email);
  }, [enabled, properties]);

  return (
    <FlightStaticDataProvider data={staticData}>
      <CreditLimitProvider>
        <ProfileProvider>
          <AccountStatusProvider>
            {children}
            {isEnabledDevTools && <DeveloperToolsContent />}
          </AccountStatusProvider>
        </ProfileProvider>
      </CreditLimitProvider>
    </FlightStaticDataProvider>
  );
}
