import { usePathname } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import { Button, Text, Token, View } from "@ctv/shared-core/src";

import { useDispatchSnackbar, useSnackbar } from "./SnackbarContext";

/**
 * @todo need to separate the notification and snackbar
 * @todo need to request designer opinion about behavior between notification and snackbar
 * @todo need to request designer opinion about the design of the notification and snackbar
 */
export default function Snackbar() {
  const timeoutRef = useRef<number>();
  const [height, setHeight] = useState(0);
  const snackbarConfig = useSnackbar();
  const dispatchSnackbar = useDispatchSnackbar();
  const [show, setShow] = useState(false);

  const pathname = usePathname();
  const firstRenderRef = useRef(true);

  function cleanUp() {
    setShow(false);
    dispatchSnackbar(undefined);
  }

  useEffect(() => {
    if (snackbarConfig) {
      setShow(true);
      timeoutRef.current = window.setTimeout(cleanUp, 5000);
    } else {
      setShow(false);
      dispatchSnackbar(undefined);
    }

    return () => {
      window.clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when snackbarConfig changes
  }, [snackbarConfig]);

  useEffect(() => {
    cleanUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run when pathname changes
  }, [pathname]);

  useEffect(() => {
    firstRenderRef.current = false;
  }, []);

  const reduceMotion = firstRenderRef.current && show;
  const rootStyle = {
    height: reduceMotion ? undefined : show ? height : 0,
  };

  if (snackbarConfig?.type === "notification") {
    return (
      <View style={[styles.root, rootStyle]}>
        <View
          onLayout={(e) => setHeight(e.nativeEvent.layout.height)}
          style={[
            styles.notification,
            snackbarConfig?.variant === "alert" && styles.alert,
            snackbarConfig?.variant === "positive" && styles.positive,
            (snackbarConfig?.variant === "normal" || undefined) &&
              styles.normal,
          ]}
        >
          <Text variant="ui-tiny-bold" style={styles.title}>
            {snackbarConfig?.title}
          </Text>
          <Text ink="white-primary" variant="ui-small-bold">
            {snackbarConfig.message}
          </Text>
          {snackbarConfig.action && (
            <View flex row spacing="s" justify="end" style={styles.btnWrapper}>
              {snackbarConfig.action.map((action, index) => (
                <Button
                  size="small"
                  key={index}
                  variant="text-white"
                  text={action?.label}
                  onPress={() => action?.onClick(cleanUp)}
                  style={{ paddingBottom: 0 }}
                />
              ))}
            </View>
          )}
        </View>
      </View>
    );
  }

  return (
    <TouchableOpacity style={[styles.root, rootStyle]} onPress={cleanUp}>
      <View
        onLayout={(e) => setHeight(e.nativeEvent.layout.height)}
        style={[
          styles.snackbar,
          snackbarConfig?.variant === "alert" && styles.alert,
          snackbarConfig?.variant === "positive" && styles.positive,
          (snackbarConfig?.variant === "normal" || undefined) && styles.normal,
        ]}
      >
        <Text ink="white-primary" variant="ui-small-bold" style={styles.text}>
          {snackbarConfig?.message}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  root: {
    // @ts-ignore
    position: "fixed",
    zIndex: 1000,
    top: 72,
    right: 64,
    // @ts-ignore
    width: "fit-content",
    minWidth: 350,
    maxWidth: "90%",
    transitionDuration: `${Token.timing.instant}ms`,
    transitionProperty: "height",
    overflow: "hidden",
    borderRadius: Token.borderRadius.normal,
  },
  snackbar: {
    alignItems: "center",
  },
  notification: {
    alignItems: "flex-start",
    padding: Token.spacing.s,
    gap: Token.spacing.xxs,
  },
  btnWrapper: {
    width: "100%",
  },
  normal: {
    backgroundColor: Token.color.businessSuit,
  },
  alert: {
    backgroundColor: Token.color.redPrimary,
  },
  positive: {
    backgroundColor: Token.color.greenPrimary,
  },
  title: {
    color: Token.opacity.opaque(Token.color.lightPrimary),
  },
  text: {
    textAlign: "center",
    paddingHorizontal: Token.spacing.l,
    paddingVertical: Token.spacing.xs,
  },
});
