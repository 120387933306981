import { StyleSheet } from "react-native";

import { Collapsible, Text, Token, useForceRender } from "@ctv/shared-core/src";
import { getPartialCookieName } from "@ctv/shared-core/src/utils/cookie";

import OverwriteApiDomainContent from "./OverwriteApiDomainContent";

type Props = {
  isVisible: boolean;
  setContentType(): void;
};

export default function OverwriteApiDomain(props: Props) {
  const { isVisible, setContentType } = props;

  const forceRender = useForceRender();

  const overwriteApiDomainCookies = getPartialCookieName("apiHost-");

  return (
    <Collapsible
      contentStyle={styles.overwriteApiDomain}
      header={
        <Text>
          Overwrite API Domain
          {!!overwriteApiDomainCookies?.length && (
            <>
              <Text>
                {" "}
                -{" "}
                <Text ink="positive">
                  Active: {overwriteApiDomainCookies.length}
                </Text>
              </Text>
            </>
          )}
        </Text>
      }
      onPress={setContentType}
      visible={isVisible}
    >
      <OverwriteApiDomainContent
        isParentVisible={isVisible}
        forceRender={forceRender}
      />
    </Collapsible>
  );
}

const styles = StyleSheet.create({
  overwriteApiDomain: {
    backgroundColor: Token.color.lightStain,
  },
});
