"use client";
import { PropsWithChildren } from "react";

import { withAuth } from "@ctv/core";
import CheckMfaGateway from "@ctv/core/auth/CheckMfaGateway";
import { FC_MFA_GATEWAY } from "@ctv/core/feature-control/constants";
import useFeatureControlSegmentation from "@ctv/core/feature-control/hooks";

import Providers from "./_contexts/Providers";
import { StaticData } from "./_contexts/flight-static-data";

type Props = {
  staticData: StaticData;
};

export default withAuth<PropsWithChildren<Props>>(function Content({
  children,
  staticData,
}) {
  const { enabled } = useFeatureControlSegmentation(FC_MFA_GATEWAY);

  if (enabled) {
    return (
      <CheckMfaGateway>
        <Providers staticData={staticData}>{children}</Providers>
      </CheckMfaGateway>
    );
  }

  return <Providers staticData={staticData}>{children}</Providers>;
});
